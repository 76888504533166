function sortByDate(a, b) {
  return Number(b.date) - Number(a.date);
}

function fillArrayToSize(array, size) {
  for (let i = array.length; i < size; i++) {
    array[i] = "0";
  }
}

function equalizeArrayLengths(array1, array2) {
  fillArrayToSize(array1, array2.length);
  fillArrayToSize(array2, array1.length);
  return array1.length;
}

function sortByVersion(a, b) {
  var aSplit = a.version.split(".");
  var bSplit = b.version.split(".");
  var maxLength = equalizeArrayLengths(aSplit, bSplit);
  for (let i = 0; i < maxLength; i++) {
    if (aSplit[i] < bSplit[i]) return 1;
    else if (aSplit[i] > bSplit[i]) return -1;
  }
  return 0;
}

function getLatestTermsofserviceByLanguage(termsofservices, language = "en") {
  var terms = termsofservices.filter((t) => t.language == language);
  terms.sort(sortByDate);
  terms.sort(sortByVersion);
  return terms[0];
}

module.exports = {
  getLatestTermsofserviceByLanguage: getLatestTermsofserviceByLanguage,
};
