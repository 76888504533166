<template>
  <div>
    <router-view></router-view>

    <tos-dialog
      v-if="tosDialog"
      v-model="tosDialog"
      :tos="latestTos"
    ></tos-dialog>
  </div>
</template>
<script>
import tosDialog from "../../components/app_termsofservice_dialog.vue";
import { verify } from "../../helpers/jwt";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      tosDialog: false,
      latestTos: null,
    };
  },
  components: {
    tosDialog,
  },
  name: "User",
  methods: {
    ...mapActions({
      fetchTermsofservicesAsync: "fetchTermsofservicesAsync",
      fetchTermsofserviceagreementsAsync: "fetchTermsofserviceagreementsAsync",
    }),
    loggedInUser() {
      return verify(localStorage.getItem("jwt")).id;
    },
  },
  computed: {
    ...mapGetters({
      allTermsofservices: "allTermsofservices",
      allTermsofserviceagreements: "allTermsofserviceagreements",
    }),
  },
  async mounted() {
    await this.fetchTermsofservicesAsync();
    await this.fetchTermsofserviceagreementsAsync();

    const toshelper = require("../../helpers/termsofservicehelper");

    var latest = toshelper.getLatestTermsofserviceByLanguage(
      this.allTermsofservices,
      "en"
    );
    this.latestTos = latest;

    var agreement = this.allTermsofserviceagreements.find(
      (a) => a.termsofserviceid == latest.id && a.userid == this.loggedInUser()
    );
    if (!agreement) return (this.tosDialog = true);
    if (agreement.agreementtime == null || agreement.agreementtime == "")
      return (this.tosDialog = true);
  },
};
</script>